import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from 'react-bootstrap/Spinner';
import { AppContext } from '@/context/AppContext';

import { delay, getSelectedWeekPrintouts } from '@/lib/api';
import { usePrintout } from '@/context/PrintoutContext';
import { useFollowing } from '@/context/FollowingContext';
import PrintoutSkeletonCard from '@/components/skeletons/PrintoutSkeletonCard';
import dynamic from 'next/dynamic';
import SkeletonCard from '@/components/skeletons/SkeletonCard';
import { useBookmark } from '@/context/BookmarkContext';
import {useWeek} from '@/context/WeekContext';
//import {useWeek} from '@/context/WeekContext';

const PrintoutCard = dynamic(() => import('@/components/partial/printoutCard'), { ssr: false });

function WeeklyPrintoutBlock({printoutsLoading, setPrintoutsLoading}) {
    const { initialLoad, setInitialLoad, handlePrint, selectedLanguages, currentAlgoliaQuery } = useContext(AppContext);
    const { initialNumberOfPrintouts, printouts, setPrintouts, algoliaPrintouts } = usePrintout();
    const { followedPublications } = useFollowing();
    const { bookmarkedPrintouts } = useBookmark();
    const {selectedWeekId, selectedWeekStartDate, currentWeekId, currentWeekStartDate} = useWeek();

    const [numberOfPrintouts, setNumberOfPrintouts] = useState(initialNumberOfPrintouts);
    const [filteredPrintouts, setFilteredPrintouts] = useState([]);
    const [showMoreSkeleton, setShowMoreSkeleton] = useState(false);

    const fetchPrintouts = async (loadMore = false) => {
        try {
            setPrintoutsLoading(true);

            let selectedWeekDate = selectedWeekStartDate;

            if (selectedWeekId === currentWeekId) {
                selectedWeekDate = currentWeekStartDate;
            }

            const response = await getSelectedWeekPrintouts(selectedWeekDate);

            setPrintouts((prevPrintouts) => (loadMore ? [...prevPrintouts, ...response.printouts] : response.printouts || []));
            setNumberOfPrintouts(response.pagination?.totalPrintouts || 0);
            if (response.printouts.length !== 0) {
                await delay(300);
            }
            setPrintoutsLoading(false);
        } catch (error) {
            console.log('Failed to fetch printouts:', error);
            setPrintoutsLoading(false);
        }
    };

    useEffect(() => {
        // Only update if printouts are available
        if (printouts && printouts.length > 0) {
            setFilteredPrintouts(printouts);
            setPrintoutsLoading(false);
            setInitialLoad(false);
        }
    }, [printouts]);

    useEffect(() => {
        if (!initialLoad) {
            fetchPrintouts().then();
        }
    }, [selectedWeekId, selectedWeekStartDate]);

    useEffect(() => {
        if (selectedLanguages.length > 0) {
            const filtered = printouts.filter((printout) =>
                selectedLanguages.includes(printout.publication.language.id)
            );
            setFilteredPrintouts(filtered);
        } else {
            setFilteredPrintouts(printouts);
        }
    }, [printouts, selectedLanguages, followedPublications]);

    const showAllPrintOuts = async () => {
        await fetchPrintouts(true);
        setShowMoreSkeleton(false);
    };

    const AlgoliaHits = () => {
        if (!algoliaPrintouts || algoliaPrintouts.length <= 0) {
            return (
                <div className="show-more text-center mt-5 justify-space-around">
                    <p id="showPrintouts" className="text-purple">
                        No items match for this week's list
                    </p>
                </div>
            );
        }

        return (
            <div className="printthumb_outer home_printthumb_outer printout-thumbnails">
                <div id="thumbnails" className="printouts_block_outer">
                    <div className="row row-cols-2 row-cols-md-4 row-cols-lg-4">
                        {
                            algoliaPrintouts.map((printout, index) => (
                                <PrintoutCard
                                    key={printout.id}
                                    printout={printout}
                                    handlePrint={handlePrint}
                                    showWeekName={false}
                                    isHomePage
                                    isBookmarkPage={false}
                                    isPublicationPage={false}
                                    bookmarkedPrints={bookmarkedPrintouts}
                                    followedPubs={followedPublications}
                                />
                            ))
                        }
                    </div>
                </div>
                <iframe id="pdf-frame" hidden={true}></iframe>
            </div>
        );
    };


    const shouldShowNoPrintoutsMessage = !printoutsLoading && filteredPrintouts.length <= 0;
    const isLoading = printoutsLoading || filteredPrintouts.length === 0;
    const hasPrintouts = filteredPrintouts.length > 0;

    return (
        <>
            <div className="printthumb_outer home_printthumb_outer printout-thumbnails my-4">
                {
                    currentAlgoliaQuery ? (
                        <AlgoliaHits />
                    ) : (
                        <>
                            <div id="thumbnails" className="printouts_block_outer">

                                {shouldShowNoPrintoutsMessage ? (
                                    <div className="col-12 text-center no_publicationall_text mt-3">
                                        No printouts found for the selected week!
                                    </div>
                                ) : (

                                    <InfiniteScroll
                                        dataLength={printouts.length}
                                        next={fetchPrintouts}
                                        className="row row-cols-2 row-cols-md-4 row-cols-lg-4"
                                        style={{overflow: 'visible'}}
                                        hasMore={false}
                                        loader={
                                            <span className="loader">
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                }
                            >
                                {isLoading && hasPrintouts ? (
                                    <SkeletonCard length={6} Component={PrintoutSkeletonCard}/>
                                ) : hasPrintouts ? (
                                    filteredPrintouts.map((printout) => (
                                        <PrintoutCard
                                            key={printout.id}
                                            printout={printout}
                                            handlePrint={handlePrint}
                                            showWeekName={false}
                                            isHomePage
                                            isBookmarkPage={false}
                                            isPublicationPage={false}
                                            bookmarkedPrints={bookmarkedPrintouts}
                                            followedPubs={followedPublications}
                                        />
                                    ))
                                ) : showMoreSkeleton && (
                                    <SkeletonCard length={8} Component={PrintoutSkeletonCard}/>
                                )}

                                <iframe id="pdf-frame" hidden></iframe>
                            </InfiniteScroll>
                            )}
                        </div>
                    </>
                    )
                }
            </div>

            {numberOfPrintouts > filteredPrintouts.length && (
                <div className="show-more text-center mt-5">
                    <p
                        id="showPrintouts"
                        role="button"
                        className="text-purple"
                        onClick={async () => {
                            setShowMoreSkeleton(true);
                            await showAllPrintOuts();
                        }}
                    >
                        Show all ({numberOfPrintouts})
                    </p>
                </div>
            )}
        </>
    );
}

export default WeeklyPrintoutBlock;
