import React, { useEffect, useState } from 'react';
import { getAds } from '@/lib/api';
import Link from 'next/link';

const fetchAds = async () => {
    let today = new Date();
    today = today.toISOString();
    return await getAds(today);
}

const AdsContainer = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [ads, setAds] = useState([]);

    useEffect(() => {
        fetchAds().then(response => {
            setAds(response);
            setIsLoading(false);
        });
    }, []);

    return (
        <div className={`${ads.length > 0 ? 'ads-container' : ''}`}>
            {!isLoading && ads.length > 0 && (
                <Link href={ads[0].url} target={`_blank`} aria-label="Visit to get more information">
                    <video className={`video1`} muted autoPlay loop>
                        <source src={ads[0].advert_image} type='video/webm' />
                    </video>
                </Link>
            )}
        </div>
    );
}

export default AdsContainer;
