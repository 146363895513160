import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getWeeksAroundDate } from '@/lib/api';
import { useWeek } from '@/context/WeekContext';

function WeekSelector() {
    const {
        adjacentWeeks,
        currentWeekId,
        currentWeek,
        isCurrentWeek,
        setIsCurrentWeek,
        setSelectedWeekId,
        setSelectedWeekStartDate,
        setAdjacentWeeks,
    } = useWeek();

    const [previousWeek, setPreviousWeek] = useState(adjacentWeeks.previousWeek);
    const [nextWeek, setNextWeek] = useState(adjacentWeeks.nextWeek);
    const [weekName, setWeekName] = useState(currentWeek?.name);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setPreviousWeek(adjacentWeeks.previousWeek);
        setNextWeek(adjacentWeeks.nextWeek);
        setWeekName(currentWeek?.name || 'Loading...');
    }, []);

    useEffect(() => {
        if (isCurrentWeek) {
            fetchWeeks().then();
        }
    }, [isCurrentWeek]);

    const fetchWeeks = async (week) => {
        if (isLoading) return;
        setIsLoading(true);

        const selectedDate = week?.week_start_date || '';
        const response = await getWeeksAroundDate(selectedDate);

        setPreviousWeek(response.previousWeek);
        setNextWeek(response.nextWeek);
        setWeekName(response.currentWeek.name);

        setSelectedWeekId(response.currentWeek.id);
        setSelectedWeekStartDate(response.currentWeek.week_start_date);
        setAdjacentWeeks(response);
        setIsLoading(false);
    };

    const handleWeekData = async (week) => {
        if (week.id === currentWeekId) {
            setIsCurrentWeek(true);
        } else {
            setIsCurrentWeek(false);
        }

        if (week && week.id) {
            await fetchWeeks(week);
        }
    };

    const handlePrevious = async () => {
        if (!isLoading && previousWeek) {
            await handleWeekData(previousWeek);
        }
    };

    const handleNext = async () => {
        if (!isLoading && nextWeek?.id) {
            await handleWeekData(nextWeek);
        }
    };

    return (
        <Row className="search_container">
            <Col className="print_head_parent" sm={12} md={5}>
                <h4 className="print_head">{`${weekName} Printouts`}</h4>
            </Col>
            <Col className="week_control_buttons" sm={12} md={7}>
                <div
                    className="weekButton"
                    onClick={handlePrevious}
                >
                    <span>←</span>
                    <span>{previousWeek?.name || 'Last Week'}</span>
                </div>
                <div
                    className={`weekButton ${!nextWeek?.id ? 'disabledButton' : ''}`}
                    onClick={handleNext}
                >
                    <span>{nextWeek?.name || 'Next Week'}</span>
                    <span>→</span>
                </div>
            </Col>
        </Row>
    );
}

export default WeekSelector;
